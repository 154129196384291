export class CartModel {
    constructor(programCategoryId, courseGroupCategoryId, programId, boardId, courseGroupId, sosId, courseName, paymentOption, sosPaymentPlanId, amount, amountDisplay, amountStrikeThroughDisplay,instituteId) {
        this.ProgramCategoryId = programCategoryId;
        this.CourseGroupCategoryId = courseGroupCategoryId;
        this.ProgramId = programId;
        this.BoardId = boardId;
        this.CourseGroupId = courseGroupId;
        this.SOSId = sosId;
        this.CourseName = courseName;
        this.PaymentOption = paymentOption;
        this.SOSPaymentPlanId = sosPaymentPlanId;
        this.Amount = amount;
        this.AmountDisplay = amountDisplay;
        this.AmountStrikethroughDisplay = amountStrikeThroughDisplay;
        this.InstituteId = instituteId;
    }
}